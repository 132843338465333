var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
            _c("h2", { staticClass: "elementSubTitle" }, [
              _vm._v(_vm._s(_vm.title))
            ])
          ]),
          _vm.isLoading
            ? _c("v-flex", { attrs: { xs12: "" } }, [
                _c(
                  "div",
                  { staticClass: "elementPanel elementPanel--spaced" },
                  [
                    _c("LoaderCard", {
                      attrs: { flat: "", type: "spinner--center" }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          !_vm.isLoading
            ? _c(
                "v-flex",
                { attrs: { xs12: "", md12: "" } },
                [
                  _vm.lastResquestedPlanningWeek &&
                  _vm.options.showLastRequestedAvailability
                    ? _c(
                        "div",
                        { staticClass: "request-alert" },
                        [
                          [
                            _c("span", [
                              _vm._v("Laatste opgevraagde beschikbaarheid:")
                            ]),
                            _c("span", { staticClass: "weeks" }, [
                              _vm._v(
                                "Week " +
                                  _vm._s(_vm.lastRequestedWeekNumber) +
                                  " -"
                              )
                            ]),
                            _c("span", { staticClass: "weekDays" }, [
                              _vm._v(
                                "(" +
                                  _vm._s(_vm.lastRequestedStartDate) +
                                  " t/m " +
                                  _vm._s(_vm.lastRequestedEndDate) +
                                  ")"
                              )
                            ])
                          ]
                        ],
                        2
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "elementPanel elementPanel--spaced" },
                    [
                      _vm.options.showRequestedAvailability
                        ? _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs6: "" } },
                                [
                                  _c(
                                    "v-select",
                                    {
                                      staticClass: "selectFilter input",
                                      attrs: {
                                        label: "Beschikbaarheid opvragen voor:",
                                        items: _vm.startWeeks,
                                        "item-value": _vm.getFirstDayOfWeek,
                                        "hide-details": ""
                                      },
                                      on: {
                                        change: _vm.selectedStartWeekChanged
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "item",
                                            fn: function(ref) {
                                              var item = ref.item
                                              return [
                                                _c("div", [
                                                  _vm._v(
                                                    " Week " +
                                                      _vm._s(
                                                        _vm.getDateLabelWeek(
                                                          item
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                  _c(
                                                    "span",
                                                    { staticClass: "weekDays" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.getDateLabelDays(
                                                            item
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ])
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        2514078302
                                      ),
                                      model: {
                                        value: _vm.selectedStartWeek,
                                        callback: function($$v) {
                                          _vm.selectedStartWeek = $$v
                                        },
                                        expression: "selectedStartWeek"
                                      }
                                    },
                                    [
                                      _c("template", { slot: "selection" }, [
                                        _c("div", [
                                          _vm._v(
                                            " Week " +
                                              _vm._s(
                                                _vm.getDateLabelWeek(
                                                  _vm.selectedStartWeek
                                                )
                                              ) +
                                              " "
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "weekDays" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDateLabelDays(
                                                    _vm.selectedStartWeek
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ])
                                      ])
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.options.showWeekChanger
                        ? _c(
                            "v-layout",
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "weekChange weekChange__prev",
                                  nativeOn: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.goToPreviousWeek($event)
                                    }
                                  }
                                },
                                [_vm._v(" keyboard_arrow_left ")]
                              ),
                              _c(
                                "h1",
                                {
                                  staticClass:
                                    "elementSubTitle elementSubTitle__accent"
                                },
                                [
                                  _vm._v(
                                    " Week " + _vm._s(_vm.weekNumber) + " "
                                  ),
                                  _c("span", { staticClass: "header-date" }, [
                                    _vm._v(_vm._s(_vm.headerDate))
                                  ])
                                ]
                              ),
                              _c(
                                "v-icon",
                                {
                                  staticClass: "weekChange weekChange__next",
                                  nativeOn: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.goToNextWeek($event)
                                    }
                                  }
                                },
                                [_vm._v("keyboard_arrow_right")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm.isLoadingTypes
                    ? _c(
                        "div",
                        { staticClass: "elementPanel elementPanel--spaced" },
                        [
                          _c("LoaderCard", {
                            attrs: { flat: "", type: "spinner--center" }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._l(_vm.organizations, function(organization, index) {
                    return _c(
                      "div",
                      {
                        key: index + "-organizations",
                        staticClass: "elementPanel elementPanel--spaced"
                      },
                      [
                        !_vm.isLoadingTypes
                          ? [
                              _c(
                                "v-layout",
                                {
                                  staticClass: "item__list",
                                  attrs: { wrap: "" }
                                },
                                [
                                  _vm.$store.state.isServiceOrganization
                                    ? _c(
                                        "v-flex",
                                        {
                                          staticClass: "label",
                                          attrs: { xs12: "" }
                                        },
                                        [
                                          _c(
                                            "v-layout",
                                            { attrs: { row: "", wrap: "" } },
                                            [
                                              _c(
                                                "v-flex",
                                                { attrs: { xs12: "" } },
                                                [
                                                  _c("h2", [
                                                    _vm._v(
                                                      _vm._s(organization.name)
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  organization.planningWeeks &&
                                  organization.planningWeeks.length
                                    ? _c(
                                        "v-flex",
                                        {
                                          staticClass: "label",
                                          attrs: { xs12: "" }
                                        },
                                        [
                                          _c(
                                            "v-layout",
                                            { attrs: { row: "", wrap: "" } },
                                            [
                                              _c(
                                                "v-flex",
                                                { attrs: { xs6: "" } },
                                                [_vm._v("Type")]
                                              ),
                                              _c(
                                                "v-flex",
                                                {
                                                  attrs: {
                                                    xs3: "",
                                                    "text-right": ""
                                                  }
                                                },
                                                [_vm._v("Afgesproken uren")]
                                              ),
                                              _c(
                                                "v-flex",
                                                {
                                                  attrs: {
                                                    xs3: "",
                                                    "text-right": ""
                                                  }
                                                },
                                                [_vm._v("Opgegeven uren")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._l(organization.planningWeeks, function(
                                    planningWeek,
                                    index
                                  ) {
                                    return _c(
                                      "v-flex",
                                      {
                                        key: index + "-availability",
                                        staticClass: "list__item",
                                        attrs: { xs12: "" }
                                      },
                                      [
                                        _c(
                                          "v-layout",
                                          { attrs: { row: "", wrap: "" } },
                                          [
                                            _c(
                                              "v-flex",
                                              { attrs: { xs6: "" } },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(planningWeek.name) +
                                                    " "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "v-flex",
                                              {
                                                attrs: {
                                                  xs3: "",
                                                  "text-xs-right": ""
                                                }
                                              },
                                              [
                                                _vm.$store.state
                                                  .isServiceOrganization
                                                  ? _c("v-text-field", {
                                                      staticClass:
                                                        "pa-0 ma-0 input--text-right",
                                                      attrs: {
                                                        placeholder: "0",
                                                        "hide-details": "",
                                                        mask: "########"
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          return _vm.saveHours(
                                                            planningWeek
                                                          )
                                                        }
                                                      },
                                                      model: {
                                                        value:
                                                          planningWeek.hours,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            planningWeek,
                                                            "hours",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "planningWeek.hours"
                                                      }
                                                    })
                                                  : _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          planningWeek.hours
                                                        )
                                                      )
                                                    ])
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-flex",
                                              {
                                                attrs: {
                                                  xs3: "",
                                                  "text-right": ""
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      planningWeek.random_hour
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  organization.planningWeeks &&
                                  organization.planningWeeks.length
                                    ? _c(
                                        "v-flex",
                                        { staticClass: "total" },
                                        [
                                          _c(
                                            "v-layout",
                                            { attrs: { wrap: "" } },
                                            [
                                              _c(
                                                "v-flex",
                                                { attrs: { xs6: "" } },
                                                [_vm._v("Totaal")]
                                              ),
                                              _c(
                                                "v-flex",
                                                {
                                                  attrs: {
                                                    xs3: "",
                                                    "text-right": ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.getTotalHours(
                                                          organization
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "v-flex",
                                                {
                                                  attrs: {
                                                    xs3: "",
                                                    "text-right": ""
                                                  }
                                                },
                                                [_vm._v("2158")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !organization.planningWeeks ||
                                  !organization.planningWeeks.length
                                    ? _c("v-flex", [
                                        _c(
                                          "span",
                                          { staticClass: "fadedText" },
                                          [
                                            _vm._v(
                                              "Geen beschikbaarheid verzoek ingediend"
                                            )
                                          ]
                                        )
                                      ])
                                    : _vm._e()
                                ],
                                2
                              )
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  }),
                  _vm.options.showRequestedAvailability
                    ? _c(
                        "div",
                        { staticClass: "elementPanel elementPanel--spaced" },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              !_vm.isLoadingTypes
                                ? _c(
                                    "v-flex",
                                    { attrs: { "pa-0": "" } },
                                    [
                                      _c(
                                        "MiButton",
                                        {
                                          attrs: {
                                            color: "primary",
                                            disabled: !_vm.selectedStartWeek,
                                            small: "true"
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.requestAvailability(
                                                $event
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(" beschikbaarheid aanvragen ")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                2
              )
            : _vm._e()
        ],
        1
      ),
      _vm.isSendingRequest
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "50vw", persistent: true },
              model: {
                value: _vm.isSendingRequest,
                callback: function($$v) {
                  _vm.isSendingRequest = $$v
                },
                expression: "isSendingRequest"
              }
            },
            [
              _c("CreateAvailabilityRequestDialog", {
                attrs: {
                  summary: _vm.organizationAvailabilitySummary,
                  selectedWeek: _vm.selectedStartWeek
                },
                model: {
                  value: _vm.isSendingRequest,
                  callback: function($$v) {
                    _vm.isSendingRequest = $$v
                  },
                  expression: "isSendingRequest"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }