var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "miDialog" }, [
    _c(
      "div",
      [
        _c(
          "v-card",
          {
            class:
              "miDialog--card " +
              (_vm.requestSend ? "success-dialog" : "warning-dialog")
          },
          [
            _c(
              "v-card-text",
              { staticClass: "miDialog--card__text" },
              [
                _vm.isSendingRequest
                  ? _c("LoaderCard", {
                      staticClass: "main-loader",
                      attrs: {
                        extraClass: "availability",
                        type: "dots",
                        flat: true
                      }
                    })
                  : _vm._e(),
                !_vm.isSendingRequest
                  ? [
                      _c(
                        "div",
                        { staticClass: "icon" },
                        [
                          _c("v-icon", [
                            _vm._v(
                              _vm._s(
                                _vm.requestSend ? "check_circle" : "warning"
                              )
                            )
                          ])
                        ],
                        1
                      ),
                      _c("h2", [_vm._v("Beschikbaarheid opvragen")]),
                      !_vm.requestSend
                        ? _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  "Weet je zeker dat je de beschikbaarheid wilt opvragen van Week " +
                                    (_vm.selectedWeek
                                      ? _vm.selectedWeek.weekNumber
                                      : "") +
                                    "?"
                                ) +
                                " "
                            )
                          ])
                        : _vm._e(),
                      _vm.requestSend
                        ? _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  "Beschikbaarheid succesvol opgevraagd voor Week " +
                                    (_vm.selectedWeek
                                      ? _vm.selectedWeek.weekNumber
                                      : "") +
                                    "."
                                ) +
                                " "
                            )
                          ])
                        : _vm._e(),
                      _vm.summary.length && !_vm.requestSend
                        ? _c(
                            "v-layout",
                            { staticClass: "hoursTable", attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c(
                                    "v-layout",
                                    {
                                      staticClass:
                                        "hoursTable__row hoursTable__row--header",
                                      attrs: { row: "", wrap: "" }
                                    },
                                    [
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass:
                                            "hoursTable__cell hoursTable__cell--header",
                                          attrs: { xs4: "" }
                                        },
                                        [_c("span", [_vm._v("Variant")])]
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass:
                                            "hoursTable__cell hoursTable__cell--header",
                                          attrs: { xs3: "" }
                                        },
                                        [_vm._v("Bureau")]
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass:
                                            "hoursTable__cell hoursTable__cell--header text-xs-right",
                                          attrs: { xs3: "" }
                                        },
                                        [_vm._v("Aantal uren")]
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass:
                                            "hoursTable__cell hoursTable__cell--header text-xs-right",
                                          attrs: { xs2: "" }
                                        },
                                        [_vm._v("Week")]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._l(_vm.summary, function(
                                    summaryItem,
                                    index
                                  ) {
                                    return _c(
                                      "v-layout",
                                      {
                                        key: "summary-" + index,
                                        staticClass: "hoursTable__row",
                                        attrs: {
                                          row: "",
                                          wrap: "",
                                          "align-center": ""
                                        }
                                      },
                                      [
                                        _c(
                                          "v-flex",
                                          {
                                            staticClass: "hoursTable__cell",
                                            attrs: { xs4: "", "ma-0": "" }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  summaryItem.type
                                                    ? summaryItem.type.name
                                                    : ""
                                                ) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "v-flex",
                                          {
                                            staticClass: "hoursTable__cell",
                                            attrs: { xs3: "", "ma-0": "" }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  summaryItem.organization
                                                    ? summaryItem.organization
                                                        .name
                                                    : ""
                                                ) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "v-flex",
                                          {
                                            staticClass:
                                              "hoursTable__cell text-xs-right",
                                            attrs: { xs3: "", "ma-0": "" }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  summaryItem.hours
                                                    ? summaryItem.hours
                                                    : ""
                                                ) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "v-flex",
                                          {
                                            staticClass:
                                              "hoursTable__cell text-xs-right",
                                            attrs: { xs2: "", "ma-0": "" }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  "Week " +
                                                    (_vm.selectedWeek
                                                      ? _vm.selectedWeek
                                                          .weekNumber
                                                      : "")
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  })
                                ],
                                2
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.requestSend
                        ? _c(
                            "v-layout",
                            {
                              staticClass: "justify-space-between",
                              attrs: { wrap: "" }
                            },
                            [
                              _c(
                                "MiButton",
                                {
                                  attrs: {
                                    color: "text-light",
                                    outline: "true"
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.cancel($event)
                                    }
                                  }
                                },
                                [_vm._v("Nee dat wil ik niet")]
                              ),
                              _c(
                                "MiButton",
                                {
                                  attrs: { color: "success" },
                                  nativeOn: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.confirm($event)
                                    }
                                  }
                                },
                                [_vm._v("Opvragen")]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.requestSend
                        ? _c(
                            "v-layout",
                            { staticClass: "justify-end", attrs: { wrap: "" } },
                            [
                              _c(
                                "MiButton",
                                {
                                  attrs: { color: "success" },
                                  nativeOn: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.cancel($event)
                                    }
                                  }
                                },
                                [_vm._v("Sluiten")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  : _vm._e()
              ],
              2
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }