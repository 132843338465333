import { DateTime } from 'luxon';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { OrganizationAvailabilitySummaryItem } from '@/components/AvailabilityRequest/AvailabilityRequest';

@Component<CreateAvailabilityRequestDialog>({})
export default class CreateAvailabilityRequestDialog extends Vue {
  @Prop()
  protected selectedWeek!: DateTime;

  @Prop({ default: () => [] })
  protected summary!: OrganizationAvailabilitySummaryItem[];

  protected requestSend = false;

  protected isSendingRequest = false;

  protected mounted() {}

  protected confirm() {
    this.isSendingRequest = true;
    setTimeout(() => {
      this.requestSend = true;
      this.isSendingRequest = false;
    }, 2000);
  }

  protected cancel() {
    this.$emit('input', false);
    this.requestSend = false;
  }
}
