import { Organization } from '@/models/Organization';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { DateTime } from 'luxon';
import { firstDayOfWeek, lastDayOfWeek } from '@/support/String';
import { Rpc } from '@/models/Rpc';
import { Options } from '@/components/mi-dialog/MiDialog';
import CreateAvailabilityRequestDialog from '@/components/AvailabilityRequest/CreateAvailabilityRequestDialog.vue';
import { ReportType as ReportTypeModel } from '@/models/ReportType';
import { PlanningWeek } from '@/models/PlanningWeek';

@Component<AvailabilityRequest>({
  components: {
    CreateAvailabilityRequestDialog,
  },
})
export default class AvailabilityRequest extends Vue {
  public $pageTitle = 'Beschikbaarheid opvragen';

  @Prop()
  protected title!: string;

  @Prop()
  protected visible!: VisibilityOptions;

  @Prop({ default: true })
  protected autoSaveHours!: boolean;

  protected isLoading = false;

  protected isLoadingTypes = false;

  protected isSuccess: boolean|null = null;

  protected startWeeks: DateTime[] = [];

  protected selectedStartWeek: DateTime | null = null;

  protected lastResquestedPlanningWeek: PlanningWeek | null = null;

  protected organizations: Organization[] = [];

  protected reportTypes: ReportTypeModel[] = [];

  protected planningWeeks: PlanningWeek[] = [];

  protected isSendingRequest = false;

  protected startDate: DateTime = DateTime.local();

  protected endDate: DateTime = DateTime.local();

  protected options: VisibilityOptions = {
    showLastRequestedAvailability: false,
    showRequestedAvailability: false,
    showWeekChanger: false,
  }

  public mounted() {
    this.options = { ...this.options, ...this.visible };
    this.emitBreadcrumb();
    this.initialize();
    this.isLoading = true;
  }

  protected initialize() {
    this.getLastRequestedPlanningWeek();
    this.createStartWeeks();
    this.getOrganizations();
    this.getReportTypes();
  }

  protected get lastRequestedWeekNumber() {
    if (! this.lastResquestedPlanningWeek || ! this.lastResquestedPlanningWeek.starts_at) {
      return '';
    }

    return DateTime.fromSQL(this.lastResquestedPlanningWeek.starts_at).weekNumber;
  }

  protected get lastRequestedStartDate() {
    if (! this.lastResquestedPlanningWeek || ! this.lastResquestedPlanningWeek.starts_at) {
      return '';
    }

    return DateTime.fromSQL(this.lastResquestedPlanningWeek.starts_at).toFormat('dd-LL-yyyy');
  }

  protected get lastRequestedEndDate() {
    if (! this.lastResquestedPlanningWeek || ! this.lastResquestedPlanningWeek.ends_at) {
      return '';
    }

    return DateTime.fromSQL(this.lastResquestedPlanningWeek.ends_at).toFormat('dd-LL-yyyy');
  }

  protected getLastRequestedPlanningWeek() {
    new PlanningWeek()
      .filter(['submitted', false])
      .sort('starts_at')
      .first()
      .then((week: PlanningWeek) => {
        this.lastResquestedPlanningWeek = week;
      });
  }

  protected findReportTypeName(reportTypId: string): string {
    const foundReportType = this.reportTypes.find((reportType: ReportTypeModel) => reportType.uuid === reportTypId);

    return foundReportType && foundReportType.name ? foundReportType.name : '-';
  }

  protected parseAvailability() {
    this.planningWeeks.forEach((planningWeek: PlanningWeek) => {
      if (planningWeek.report_type_id) {
        planningWeek.name = this.findReportTypeName(planningWeek.report_type_id);
      }
    });

    this.organizations.forEach((organization: Organization) => {
      if (this.$store.state.isServiceOrganization) {
        const foundPlanningWeeks = this.planningWeeks.filter((planningWeek: PlanningWeek) => planningWeek.organization_id === organization.id);

        if (foundPlanningWeeks && foundPlanningWeeks.length) {
          organization.planningWeeks = foundPlanningWeeks;
        }
      } else {
        organization.planningWeeks = this.planningWeeks;
      }
    });

    this.isLoadingTypes = false;
  }

  protected getOrganizations() {
    new Organization()
      .getAllExperts()
      .then((organizations: Organization[]) => {
        this.organizations = organizations;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  protected getReportTypes() {
    new ReportTypeModel()
      .all()
      .then((reportTypes: ReportTypeModel[]) => {
        this.reportTypes = reportTypes;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  protected createStartWeeks() {
    const today = DateTime.local();
    const weekStartDate = firstDayOfWeek(today);

    for (let week = 0; week < 12; week += 1) {
      const date = weekStartDate.plus({ week });
      this.startWeeks.push(date);
    }

    this.selectedStartWeek = this.startWeeks[0];
    this.fetchPlanningWeeks(this.selectedStartWeek);
  }

  protected getDateLabelDays(date: DateTime): string {
    if (! date) {
      return '';
    }
    return `(${firstDayOfWeek(date).toFormat('dd-LL-yyyy')} - ${lastDayOfWeek(date).toFormat('dd-LL-yyyy')})`;
  }

  protected getDateLabelWeek(date: DateTime) {
    return date ? date.weekNumber : '';
  }

  protected getDateLabelFirstDay(date: DateTime): string {
    if (! date) {
      return '';
    }
    return `${firstDayOfWeek(date).toFormat('dd-LL-yyyy')}`;
  }

  protected getDateLabelLastDay(date: DateTime): string {
    return `${lastDayOfWeek(date).toFormat('dd-LL-yyyy')}`;
  }

  protected getFirstDayOfWeek(date: DateTime) {
    return date;
  }

  protected getLastDayOfWeek(date: DateTime) {
    const lastDay = lastDayOfWeek(date);
    return lastDay;
  }

  protected requestAvailability() {
    this.$store.dispatch('openDialog', this.dialogOptionsAvailabilityRequest);
  }

  protected sendAvailabilityRequest() {
    this.isLoading = true;

    const payload: ParsedPlanningWeek[] = [];
    this.organizations.forEach((organization: Organization) => {
      if (! organization.planningWeeks) {
        return;
      }

      organization.planningWeeks.forEach((planningWeek: PlanningWeek) => {
        if (! this.selectedStartWeek) {
          return;
        }

        const parsedPlanningWeek = {
          starts: firstDayOfWeek(this.selectedStartWeek).toFormat('yyyy-LL-dd'),
          ends: lastDayOfWeek(this.selectedStartWeek).toFormat('yyyy-LL-dd'),
          organization: planningWeek?.organization_id || '',
          report_type: planningWeek?.report_type_id || '',
          hours: planningWeek.hours ? planningWeek.hours : 0,
        };

        payload.push(parsedPlanningWeek);
      });
    });

    new Rpc()
      .execute('planning:request', {
        weeks: payload,
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  protected saveHours(planningWeek: PlanningWeek) {
    if (! this.autoSaveHours || ! planningWeek || ! this.selectedStartWeek) {
      return;
    }

    const payload = {
      starts: firstDayOfWeek(this.selectedStartWeek).toFormat('yyyy-LL-dd'),
      ends: lastDayOfWeek(this.selectedStartWeek).toFormat('yyyy-LL-dd'),
      organization: planningWeek.organization_id,
      report_type: planningWeek.report_type_id,
      hours: planningWeek.hours ? planningWeek.hours : 0,
    };

    new Rpc()
      .execute('planning:request', {
        weeks: [payload],
      })
      .then(() => {})
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Afgesproken uren' },
        ],
      });
  }

  protected get dialogOptionsAvailabilityRequest(): Options {
    return {
      title: 'Beschikbaarheid opvragen',
      text: `Weet je zeker dat je de beschikbaarheid wilt opvragen van Week ${this.selectedStartWeek ? (this.selectedStartWeek as DateTime).weekNumber : ''}?`,
      type: 'warning',
      buttons: {
        confirm: {
          text: 'Opvragen',
          action: () => {
            this.sendAvailabilityRequest();
          },
        },
        cancel: {
          text: this.$t('dialogOptions.button.cancel').toString(),
          color: 'text-light',
          action: () => {
            //
          },
        },
      },
    };
  }

  protected randomValue() {
    return Math.floor(Math.random() * (50 - 50 + 150)) + 50;
  }

  @Watch('$route', { deep: true })
  public routeChanged(to: any, from: any) {
    this.emitBreadcrumb();
  }

  protected get headerDate(): string {
    return this.startDate ? `(${this.startDate.toFormat('dd-LL-yyyy')} t/m ${lastDayOfWeek(this.endDate).toFormat('dd-LL-yyyy')})` : '';
  }

  protected get weekNumber(): string | number {
    return this.startDate ? this.startDate.weekNumber : '';
  }

  protected getTotalHours(organization: Organization): number {
    if (! organization.planningWeeks) {
      return 0;
    }

    let total = 0;
    organization.planningWeeks.forEach((planningWeek: PlanningWeek) => {
      total += planningWeek && planningWeek.hours ? Number(planningWeek.hours) : 0;
    });

    return total;
  }

  protected fetchPlanningWeeks(date: DateTime) {
    this.planningWeeks = [];
    this.isLoadingTypes = true;

    const filter = {
      period: {
        start: firstDayOfWeek(date).toFormat('yyyy-LL-dd'),
        end: lastDayOfWeek(date).toFormat('yyyy-LL-dd'),
      },
    };

    new PlanningWeek()
      .filter(filter)
      .sort('starts_at')
      .all()
      .then((planningWeeks: PlanningWeek[]) => {
        if (planningWeeks.length) {
          this.planningWeeks = planningWeeks;
          this.parseAvailability();
        } else {
          this.generateEmptyPlanningWeeks(date);
        }
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected generateEmptyPlanningWeeks(date: DateTime) {
    this.organizations.forEach((organization: Organization) => {
      organization.planningWeeks = [];
      this.reportTypes.forEach((reportType: ReportTypeModel) => {
        if (! organization || ! organization.planningWeeks) {
          return;
        }

        const planningWeek = new PlanningWeek({
          organization_id: (organization.id as string),
          report_type_id: reportType.uuid,
          week: date.weekNumber,
          year: date.year,
          starts_at: `${firstDayOfWeek(date).toFormat('yyyy-LL-dd')} 00:00:00`,
          ends_at: `${lastDayOfWeek(date).toFormat('yyyy-LL-dd')} 23:59:59`,
          hours: 0,
          name: reportType.uuid ? this.findReportTypeName(reportType.uuid) : '-',
        });

        organization.planningWeeks.push(planningWeek);
        this.planningWeeks.push(planningWeek);
      });
    });

    this.isLoadingTypes = false;
  }

  protected selectedStartWeekChanged() {
    if (! this.selectedStartWeek) {
      return;
    }

    this.fetchPlanningWeeks(this.selectedStartWeek);
  }

  protected goToPreviousWeek() {
    this.startDate = this.startDate.minus({ weeks: 1 });
    this.endDate = this.startDate.plus({ days: 6 });
    this.fetchPlanningWeeks(this.startDate);
  }

  protected goToNextWeek() {
    this.startDate = this.startDate.plus({ weeks: 1 });
    this.endDate = this.startDate.plus({ days: 6 });
    this.fetchPlanningWeeks(this.startDate);
  }
}

export interface OrganizationAvailabilitySummaryItem {
  organization: Organization | null;
  type: ReportTypeModel | null;
  hours: string;
}

interface VisibilityOptions {
  showLastRequestedAvailability?: boolean,
  showRequestedAvailability?: boolean,
  showWeekChanger?: boolean,
}

interface ParsedPlanningWeek {
  starts: string;
  ends: string;
  organization: string;
  report_type: string;
  hours: number;
}
